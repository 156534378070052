@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* @import url(../src/assets/css/stylesheet.css); */

section.pricing {
  background: #007bff;
  background: radial-gradient(#1D2856, #0c1022);
  margin-top: 25px;
}
.pricing .card {
  border: none;
  height: 100% !important;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}
.pricing hr {
  margin: 1.5rem 0;
}

.class-font {
  font-family: "SF Pro Display" !important;
}

.pricing .card-title {
  font-family: "SF Pro Display" !important;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-family: "SF Pro Display" !important;
  font-weight: 700;
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  font-family: "SF Pro Display" !important;
  margin-bottom: 1rem;
}

/* .pricing .text-muted {
  opacity: 0.7;
} */

.pricing .btn {
  width: 100%;
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  background: #1D2856;
  transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card .btn:hover {
    background: #E76A34;
    border: none;
  }
}